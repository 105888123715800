import { default as _91doc_93pUE7IgByWyMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue?macro=true";
import { default as capture39ckDi88fyMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue?macro=true";
import { default as guest_45profileQHOAFM4MUiMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue?macro=true";
import { default as guest_45signin_45code6m8u8q91OSMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue?macro=true";
import { default as guest_45signinnlqpvpYeOfMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue?macro=true";
import { default as indexKMjhLFL3seMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue?macro=true";
import { default as inviteDSdA2EUo0yMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue?macro=true";
import { default as album8O4ZI3xlroMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue?macro=true";
import { default as carouseluNnifr5bhlMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue?macro=true";
import { default as indexGo7JNMhysZMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue?macro=true";
import { default as createKjKsGFBZQAMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue?macro=true";
import { default as index1DD9JKLxiDMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue?macro=true";
import { default as signingEw9XQ3og4Meta } from "/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue?macro=true";
import { default as index2pN7WtNjzBMeta } from "/home/benjamin/projects/personal/instabooth.app/pages/index.vue?macro=true";
export default [
  {
    name: "booths-eventId-doc___el",
    path: "/el/booths/:eventId()/:doc()",
    meta: _91doc_93pUE7IgByWyMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue")
  },
  {
    name: "booths-eventId-doc___en___default",
    path: "/booths/:eventId()/:doc()",
    meta: _91doc_93pUE7IgByWyMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue")
  },
  {
    name: "booths-eventId-doc___en",
    path: "/en/booths/:eventId()/:doc()",
    meta: _91doc_93pUE7IgByWyMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/[doc].vue")
  },
  {
    name: "booths-eventId-capture___el",
    path: "/el/booths/:eventId()/capture",
    meta: capture39ckDi88fyMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue")
  },
  {
    name: "booths-eventId-capture___en___default",
    path: "/booths/:eventId()/capture",
    meta: capture39ckDi88fyMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue")
  },
  {
    name: "booths-eventId-capture___en",
    path: "/en/booths/:eventId()/capture",
    meta: capture39ckDi88fyMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/capture.vue")
  },
  {
    name: "booths-eventId-guest-profile___el",
    path: "/el/booths/:eventId()/guest-profile",
    meta: guest_45profileQHOAFM4MUiMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue")
  },
  {
    name: "booths-eventId-guest-profile___en___default",
    path: "/booths/:eventId()/guest-profile",
    meta: guest_45profileQHOAFM4MUiMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue")
  },
  {
    name: "booths-eventId-guest-profile___en",
    path: "/en/booths/:eventId()/guest-profile",
    meta: guest_45profileQHOAFM4MUiMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-profile.vue")
  },
  {
    name: "booths-eventId-guest-signin-code___el",
    path: "/el/booths/:eventId()/guest-signin-code",
    meta: guest_45signin_45code6m8u8q91OSMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue")
  },
  {
    name: "booths-eventId-guest-signin-code___en___default",
    path: "/booths/:eventId()/guest-signin-code",
    meta: guest_45signin_45code6m8u8q91OSMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue")
  },
  {
    name: "booths-eventId-guest-signin-code___en",
    path: "/en/booths/:eventId()/guest-signin-code",
    meta: guest_45signin_45code6m8u8q91OSMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin-code.vue")
  },
  {
    name: "booths-eventId-guest-signin___el",
    path: "/el/booths/:eventId()/guest-signin",
    meta: guest_45signinnlqpvpYeOfMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue")
  },
  {
    name: "booths-eventId-guest-signin___en___default",
    path: "/booths/:eventId()/guest-signin",
    meta: guest_45signinnlqpvpYeOfMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue")
  },
  {
    name: "booths-eventId-guest-signin___en",
    path: "/en/booths/:eventId()/guest-signin",
    meta: guest_45signinnlqpvpYeOfMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/guest-signin.vue")
  },
  {
    name: "booths-eventId___el",
    path: "/el/booths/:eventId()",
    meta: indexKMjhLFL3seMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue")
  },
  {
    name: "booths-eventId___en___default",
    path: "/booths/:eventId()",
    meta: indexKMjhLFL3seMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue")
  },
  {
    name: "booths-eventId___en",
    path: "/en/booths/:eventId()",
    meta: indexKMjhLFL3seMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/index.vue")
  },
  {
    name: "booths-eventId-invite___el",
    path: "/el/booths/:eventId()/invite",
    meta: inviteDSdA2EUo0yMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue")
  },
  {
    name: "booths-eventId-invite___en___default",
    path: "/booths/:eventId()/invite",
    meta: inviteDSdA2EUo0yMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue")
  },
  {
    name: "booths-eventId-invite___en",
    path: "/en/booths/:eventId()/invite",
    meta: inviteDSdA2EUo0yMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/booths/[eventId]/invite.vue")
  },
  {
    name: "events-eventId-album___el",
    path: "/el/events/:eventId()/album",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue")
  },
  {
    name: "events-eventId-album___en___default",
    path: "/events/:eventId()/album",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue")
  },
  {
    name: "events-eventId-album___en",
    path: "/en/events/:eventId()/album",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/album.vue")
  },
  {
    name: "events-eventId-carousel___el",
    path: "/el/events/:eventId()/carousel",
    meta: carouseluNnifr5bhlMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue")
  },
  {
    name: "events-eventId-carousel___en___default",
    path: "/events/:eventId()/carousel",
    meta: carouseluNnifr5bhlMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue")
  },
  {
    name: "events-eventId-carousel___en",
    path: "/en/events/:eventId()/carousel",
    meta: carouseluNnifr5bhlMeta || {},
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/carousel.vue")
  },
  {
    name: "events-eventId___el",
    path: "/el/events/:eventId()",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId___en___default",
    path: "/events/:eventId()",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-eventId___en",
    path: "/en/events/:eventId()",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/[eventId]/index.vue")
  },
  {
    name: "events-create___el",
    path: "/el/events/create",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue")
  },
  {
    name: "events-create___en___default",
    path: "/events/create",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue")
  },
  {
    name: "events-create___en",
    path: "/en/events/create",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/create.vue")
  },
  {
    name: "events___el",
    path: "/el/events",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue")
  },
  {
    name: "events___en___default",
    path: "/events",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/index.vue")
  },
  {
    name: "events-signin___el",
    path: "/el/events/signin",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue")
  },
  {
    name: "events-signin___en___default",
    path: "/events/signin",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue")
  },
  {
    name: "events-signin___en",
    path: "/en/events/signin",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/events/signin.vue")
  },
  {
    name: "index___el",
    path: "/el",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/index.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/benjamin/projects/personal/instabooth.app/pages/index.vue")
  }
]