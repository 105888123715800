import { useSessionStore } from "./../stores/session";
import dayjs from "dayjs";
import type { Event as Booth } from "~/model.js";
import {
  getDownloadURL,
  type FullMetadata,
  ref as _ref,
  list,
  type ListOptions,
  getMetadata,
} from "firebase/storage";
import type { ParsedContent } from "@nuxt/content";
export interface FeedPointer {
  path: string;
  url: string;
  meta: FullMetadata;
}
import type { DocContainer, DocBody } from "~/model";
import { doc, updateDoc, type DocumentSnapshot } from "firebase/firestore";

export const handleMobileNumberInput = (event: Event) => {
  const { key } = event as KeyboardEvent;
  if (
    key === "Tab" ||
    key === "Backspace" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight"
  ) {
    return;
  }

  if (!Number.isInteger(Number.parseInt(key))) {
    event.preventDefault();
  }
};

export const formatDateToLocale = (isoDateString: string) =>
  dayjs(isoDateString).format("llll");

export interface MainContent extends ParsedContent {
  welcome1: string;
  welcome2: string;
  entrance: string;
  eventStartsIn: string;
  eventIsClosed: string;
  back: string;
  cancel: string;
  working: string;
  countdown: {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  };
  signInIntro: string;
  signIn: string;
  signOut: string;
  userProfile: string;
  photoBoothUploadIntro: string;
  photoBoothCommentPlaceholder: string;
  photoBoothTake: string;
  photoBoothUpload: string;
  photoBoothUploadFailed: string;
  inviteIntro: string;
  inviteOpenInNew: string;
  userProfileIntro1: string;
  userProfileIntro2: string;
  userProfileIntro3: string;
  userProfileNickname: string;
  confettiMsg1: string;
  confettiMsg2: string;
  startCarousel: string;
  showStats: string;
  calculating: string;
  calculated: string;
  refresh: string;
  totalContributors: string;
  totalPhotos: string;
  totalSize: string;
  contributorsList: string;
  contributorsSorting: string;
  contributor: string;
  photoCount: string;
  signInIntro2: string;
  send: string;
  termsOfUse: string;
  privacyPolicy: string;
  consent_1_3: string;
  consent_2_3: string;
  consent_3_3: string;
  captureTargetMessage: string;
  captureTargetMessage2: string;
  greeting: string;
  fileTooBig: string;
}

export const getTenantId = async () =>
  (await useSessionStore().auth?.currentUser?.getIdTokenResult())?.claims
    .tenantId as string;

export const buildAlbumPath = async (ev: EventInfo) => {
  const route = useRoute();

  const { eventId } = route.params;
  if (!eventId) return;

  const tenantId = ev.tenantId;
  const ownerUid = ev.ownerUid;

  return `/albums/${tenantId}/${ownerUid}/${eventId}`;
};

export const buildBundlePath = async (ev: EventInfo) => {
  const route = useRoute();

  const { eventId } = route.params;
  if (!eventId) return;

  const tenantId = ev.tenantId;
  const ownerUid = ev.ownerUid;

  return `/bundles/${tenantId}/${ownerUid}/${eventId}`;
};

export const assertEventId = async () => {
  const route = useRoute();
  const { eventId } = route.params;
  if (!eventId) return;

  const sessionStore = useSessionStore();
  if (sessionStore.eventName === eventId) return;

  const evtRef = _ref(sessionStore.storage!, `/toc/${eventId}.json`);
  try {
    // sessionStore.setLoading(true);
    const url = await getDownloadURL(evtRef);
    sessionStore.setEventInfo(await $fetch<EventInfo>(url));
    sessionStore.setEventName(eventId as string);
  } catch (error) {
    navigateTo("/"); //TODO proper error page
  } finally {
    // sessionStore.setLoading(false);
  }
};

export const getFirebaseStoragePage = async (
  prefix: string,
  listOptions?: ListOptions
) => {
  const sessionStore = useSessionStore();
  const maxResults = listOptions?.maxResults ?? 100;
  const pageToken = listOptions?.pageToken;

  // Create a reference under which you want to list
  try {
    const listRef = _ref(sessionStore.storage!, prefix);
    return await list(listRef, { maxResults, pageToken });
  } catch (e) {
    //ignore
  }
};

export const toDocData = <T extends DocBody>(ref: DocumentSnapshot) => {
  return <DocContainer<T>>{ id: ref.id, data: ref.data() as T };
};

export const isEventOngoing = (e: Booth) =>
  dayjs().isAfter(e.startTime) && dayjs().isBefore(e.endTime);

export const isEventUpcoming = (e: Booth) => dayjs().isBefore(e.startTime);

export const isEventCompleted = (e: Booth) => dayjs().isAfter(e.endTime);

export const isEventCompletedAndEmpty = (e: Booth) =>
  isEventCompleted(e) && e.stats && e.stats.photoStorageSize < 1;

export const isEventReadyForBundling = (e: Booth) =>
  isEventCompleted(e) &&
  !e.actions?.bundled?.timestamp &&
  !e.actions?.downloaded?.length &&
  !e.actions?.archived;

export const isEventReadyForDownload = (e: Booth) =>
  isEventCompleted(e) &&
  e.actions?.bundled?.timestamp &&
  !isEventReadyForArchive(e) &&
  !isEventArchived(e);

export const isEventReadyForArchive = (e: Booth) => {
  if (!isEventCompleted(e) || !e?.actions?.bundled?.files || isEventArchived(e))
    return false;

  for (const b of e.actions.bundled.files) {
    if (!e.actions!.downloaded?.find((d) => d.file === b)) {
      return false;
    }
  }
  return true;
};

export const isEventArchived = (e: Booth) =>
  !e.actions?.archived?.timestamp ? false : true;

export const isCarouselAvailable = (e: Booth) =>
  isEventOngoing(e) || isEventReadyForBundling(e);

export const formatFileSize = (bytes: number) => {
  const { locale } = useI18n();
  const isGB = bytes / Math.pow(1000.0, 3) >= 1.0;

  const s = Intl.NumberFormat(locale.value, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(bytes / (isGB ? Math.pow(1000, 3) : Math.pow(1000, 2)));

  return `${s} ${isGB ? "GB" : "MB"}`;
};

export const calcAndUpdateStats = async (event: DocContainer<Booth>) => {
  let lastPageToken: string | undefined;
  let first = true;

  event.data.stats = {
    timestamp: dayjs().toISOString(),
    photographerCount: 0,
    photoCount: 0,
    videoCount: 0,
    photoStorageSize: 0,
    topPhotographers: [],
  };

  const albumPath = (await buildAlbumPath(event.data))!;
  while (first || lastPageToken) {
    first = false;
    const page = await getFirebaseStoragePage(albumPath, {
      maxResults: 50,
      pageToken: lastPageToken,
    });
    lastPageToken = page?.nextPageToken;

    if (page) {
      const metas = <Array<Promise<FullMetadata>>>[];

      for (const item of page.items) {
        metas.push(getMetadata(item));
      }

      for (const meta of await Promise.all(metas)) {
        if (
          meta.contentType?.startsWith("image") ||
          meta.contentType?.startsWith("video")
        ) {
          const newPhoto = meta.contentType?.startsWith("image") ? 1 : 0;
          const newVideo = meta.contentType?.startsWith("video") ? 1 : 0;
          event.data.stats.photoCount += newPhoto;
          event.data.stats.videoCount += newVideo;

          event.data.stats.photoStorageSize =
            event.data.stats.photoStorageSize + meta.size;

          if (meta.customMetadata?.userHandle) {
            const photographer = event.data.stats.topPhotographers.find(
              (i) => i.userHandle === meta.customMetadata?.userHandle
            );
            if (photographer) {
              (photographer.photoCount += newPhoto),
                (photographer.videoCount += newVideo),
                (photographer.lastUpload = dayjs(
                  meta.timeCreated
                ).toISOString());
            } else {
              event.data.stats.topPhotographers.push({
                phoneNumber: meta.customMetadata?.phoneNumber ?? "unknown",
                userHandle: meta.customMetadata!.userHandle,
                photoCount: newPhoto,
                videoCount: newVideo,
                lastUpload: dayjs(meta.timeCreated).toISOString(),
              });
              event.data.stats.photographerCount++;
            }
          }
        }
      }
    }
  }

  const sessionStore = useSessionStore();
  updateDoc(doc(sessionStore.db!, "events", event.id), {
    stats: event.data.stats!,
  });
};

export const getPhotoTimeFromName = (name: string) => {
  return dayjs(name.split("_")[0]).toDate();
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const toImageKitUrl = (
  url: string,
  options: {
    type: "image" | "video";
    thumbnail?: boolean;
    withOverlay?: boolean;
  }
) => {
  const _url = new URL(url);
  let imagekit = false;
  let resp = `${_url.protocol}//`;

  if (_url.hostname === "firebasestorage.googleapis.com") {
    imagekit = true;
    resp += "ik.imagekit.io/instabooth";
  } else {
    resp += _url.host;
  }

  resp += _url.pathname;

  if (imagekit && options.type === "video") {
    if (_url.pathname.endsWith(".quicktime")) {
      resp += "/ik-video.mp4";
    }
    if (options.thumbnail) {
      resp += "/ik-thumbnail.jpg";
    }
  }

  resp += _url.search;

  if (imagekit && options.type === "video" && options.thumbnail) {
    resp += "&tr=h-100";
  } else if (imagekit && options.type === "image" && options.thumbnail) {
    resp += "&tr=h-100";
  }

  if (imagekit && options.type === "image" && options.withOverlay) {
    resp += "&tr=l-image,i-qr-branding.svg,lx-N50,ly-N50,w-bw_div_6,l-end";
  }

  return resp;
};

export const isRunningLocally = () => useRuntimeConfig().public.emulate;

export const getMediaUrl = async (
  eventId: String,
  fullPath: string,
  type: "image" | "video",
  thumbnail?: boolean
) => {
  const cache = await window.caches.open(`booth${eventId}`);
  const cacheKey = fullPath + (thumbnail ? "/thumbnail" : "");
  let resp = await cache.match(cacheKey);
  if (resp) {
    // alert("cahce hit for photo " + cacheKey);
    return URL.createObjectURL(await resp.blob());
  }

  const sessionStore = useSessionStore();
  const pref = _ref(sessionStore.storage!, fullPath);
  let url = await getDownloadURL(pref);

  if (type === "image") {
    url = toImageKitUrl(url, { type: "image", thumbnail: thumbnail });
  } else {
    if (!thumbnail || isRunningLocally()) {
      url = toImageKitUrl(url, { type: "video" });
    } else {
      url = toImageKitUrl(url, { type: "video", thumbnail: true });
    }
  }

  resp = await fetch(url, { method: "GET" });
  cache.put(cacheKey, resp.clone());
  return URL.createObjectURL(await resp.blob());
};

export const getMediaMeta = async (fullPath: string) => {
  const cache = window.sessionStorage;
  const resp = cache.getItem(fullPath);
  if (resp) {
    // console.log("cahce hit for meta " + path);
    return JSON.parse(resp) as FullMetadata;
  }

  const sessionStore = useSessionStore();
  const pref = _ref(sessionStore.storage!, fullPath);
  const meta = await getMetadata(pref);
  cache.setItem(fullPath, JSON.stringify(meta));
  return meta;
};

export const getPhotoAsFile = async (path: string) => {
  const sessionStore = useSessionStore();
  const photoRef = _ref(sessionStore.storage!, path);
  let url = await getDownloadURL(photoRef);
  url = toImageKitUrl(url, {
    type: "image",
    withOverlay: true,
  });

  // console.log(url);
  const blob = await $fetch<Blob>(url, {
    method: "GET",
  });

  return new File([blob], "instabooth.jpg", {
    type: "image/jpeg",
  });
};
